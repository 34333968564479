import { global } from './global'
import { store } from '@/store'

let _baseurl = global.baseApiUrl + 'comisiones/'

export default {
  getComisionActivacion(activacion) {
    return global.axios.get(_baseurl + 'get-movimiento-activacion?activationid=' + activacion + '&canalasociadoid=' + store.getters.user.asociado.canalAsociadoId)
  },
  getComponentesByTipoProducto(tipoProducto) {
    return global.axios.get(_baseurl + 'get-componentes-by-tipo-producto?productotipoid=' + tipoProducto)
  },
  getComponentesByProducto(producto) {
    return global.axios.get(_baseurl + 'get-componentes-by-producto?productId=' + producto)
  },
  getComponentesIncentivosByCanal(canal, asociadoComisionGroupID) {
    return global.axios.get(_baseurl + 'get-componentes-incentivos-by-canal?canalId=' + canal + '&asociadoComisionGroupID='+asociadoComisionGroupID)
  },
  getComponentesIncentivosByCanalCorte(canal, asociadoComisionGroupID,corteID) {
    return global.axios.get(_baseurl + 'get-componentes-incentivos-by-canal-corte?canalId=' + canal + '&asociadoComisionGroupID='+asociadoComisionGroupID+ '&corteID='+ corteID)
  },
  getComisionActivacionMulti(data) {
    let param = []
    data.forEach(function (o) { param.push({ ActivationId: o.ActivationID, CanalAsociadoId: store.getters.user.asociado.canalAsociadoId }) })
    return global.axios.post(_baseurl + 'get-movimiento-activacion-multi', param, { responseType: 'blob', headers: { 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/json' } })
  },
  getComisionEmpleado(request) {
    return global.axios.post(_baseurl + 'get-comision-empleado', request)
  },
  getComisionActivacionEmpleado(request) {
    return global.axios.post(_baseurl + 'get-movimiento-activacion-empleado', request)
  },
  getIncentivoActivacion(request) {
    return global.axios.post(_baseurl + 'get-incentivo-activaciones-empleado', request)
  },
  getIncentivos(fechaInicial) {
    return global.axios.get(_baseurl + 'get-incentivos?fechaInicial=' + fechaInicial + '&canalAsociadoId=' + store.getters.user.asociado.canalAsociadoId)
  },
  getLineasNoPagadasActivacionEmpleado(request) {
    return global.axios.post(_baseurl + 'get-lineas-pagadas-activacion-empleado', request)
  },
  
  getComisionEmpleadoRecurrente(request) {
    return global.axios.post(_baseurl + 'get-comision-empleado-recurrente', request)
  },
}
